import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

interface PopupProps {
    selectedTerminalId: string | null;
    show: boolean;
    onClose: () => void;
    onConfirm: (selectedOption: string) => void;
}

const Popup: React.FC<PopupProps> = ({ selectedTerminalId, show, onClose, onConfirm }) => {
    const [selectedOption, setSelectedOption] = useState<string>('');

    // Hard-coded options for the dropdown
    const dropdownOptions = ['BGR', 'CGO', 'KL2', 'MTB'];

    useEffect(() => {
        if (selectedTerminalId) {
            setSelectedOption(selectedTerminalId);
        }
    }, [selectedTerminalId]);

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOption(event.target.value);
    };

    const handleConfirm = () => {
        onConfirm(selectedOption);
        onClose();
    };

    return (
        <Modal show={show} onHide={onClose} centered size="sm">
            <Modal.Header closeButton>
                <Modal.Title>Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Terminals</Form.Label>
                    <Form.Control
                        as="select"
                        value={selectedOption}
                        onChange={(event) =>
                            handleSelectChange(event as unknown as React.ChangeEvent<HTMLSelectElement>) // Explicit cast
                        }
                    >
                        <option value="all">All</option>
                        {dropdownOptions.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={handleConfirm}
                    disabled={!selectedOption} // Disable if no option is selected
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default Popup;
