import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FlightDetails } from '../interface/flight.details';
import moment from "moment";
import { RemarkCode } from '../utils/remark.code';
import { Cities } from '../utils/cities';
// import socket from '../socket';
import { getDisplayColor, handleGateChange } from '../utils/common.utils';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import FilterPopup from './FilterPopup';
import { io, Socket } from 'socket.io-client';
import { createSocket } from '../create.socket';

const url = process.env.REACT_APP_API_URL.split("://");
const socketURL = `ws${url[0] === "https" ? "s" : ""}://${url[1]}`;
const STORAGE_TERMINAL_KEY = "ArrivalSelectedTerminalId";
let terminalId = localStorage.getItem(STORAGE_TERMINAL_KEY);
let socket = createSocket(terminalId || "all");
socket.connect();

interface ArrivalComponentProps {
  updateArrivals: (data: Date) => void;
  rowsPerPage: number;
  isDateChanges?: boolean
}

const ArrivalComponent: React.FC<ArrivalComponentProps> = ({ updateArrivals, rowsPerPage, isDateChanges }) => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limit = queryParams.get('limit');
  const path = location.pathname;
  const lastPathSegment = path.substring(path.lastIndexOf('/') + 1);

  const [flights, setFlights] = useState<FlightDetails[]>([]);
  const displayOffset = process.env.REACT_APP_DISPLAY_OFFSET_MINS || 20;
  const bagMovementMins = process.env.REACT_APP_BAG_MOVEMENT_MINS || 5;
  const timezone = process.env.REACT_APP_TIMEZONE || 8;
  const [stationIndices, setStationIndices] = useState<{ [key: string]: number }>({});
  const [codeShareFlightIndices, setCodeShareFlightIndices] = useState<{ [key: string]: number }>({});
  const [animatedFlightIds, setAnimatedFlightIds] = useState<{ [key: string]: boolean }>({}); // Track which flights are animating
  const cargoCodes = ["F", "M", "H", "V", "A", "K"];
  const [selectedTerminalId, setSelectedTerminalId] = useState(localStorage.getItem(STORAGE_TERMINAL_KEY) || "all");
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [isFading, setIsFading] = useState(false); // Track fading effect


  // Automatic page flipping

  const totalPages = Math.ceil(flights.length / rowsPerPage);
  const currentFlights = flights.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  useEffect(() => {

    if (totalPages === 1) {
      setCurrentPage(1);
      return;
    };
    // Apply fade-out and fade-in effect on page flip
    const interval = setInterval(() => {
      setIsFading(true); // Trigger fade-out
      setTimeout(() => {
        setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : 1));
        setIsFading(false); // Reset to fade-in
      }, 500); // Duration should match CSS transition
    }, currentPage === 1 ? 10 * 1000 : 5 * 1000);

    return () => clearInterval(interval);
  }, [currentPage, totalPages]);

  // Navigate between pages
  const goToPreviousPage = () => {
    setIsFading(true); // Trigger fade-out
    setTimeout(() => {
      setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : Math.ceil(flights.length / rowsPerPage)));
      setIsFading(false); // Reset to fade-in
    }, 500); // Duration should match CSS transition
  };

  const goToNextPage = () => {
    setIsFading(true); // Trigger fade-out
    setTimeout(() => {
      setCurrentPage((prevPage) => (prevPage < Math.ceil(flights.length / rowsPerPage) ? prevPage + 1 : 1));
      setIsFading(false); // Reset to fade-in
    }, 500); // Duration should match CSS transition
  };

  // Handle socket.io connection for real-time data

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected to server with socket ID:", socket.id);
    });

    return () => {
      socket.off("connect");
    };
  }, []);

  useEffect(() => {
    socket.on('refresh_app', (message) => {
      console.log(message);
      window.location.reload();
    });

    return () => {
      socket.off("refresh_app");
    };
  }, []);

  useEffect(() => {

    socket.on('flight_update_arrival', (flightData: FlightDetails) => {
      setFlights((prevFlights) => {
        const flightIndex = prevFlights.findIndex(flight => flight._id === flightData._id);
        let updatedFlights;
        if (flightIndex !== -1) {
          updatedFlights = [...prevFlights];

          if (flightData?.remarkCode && updatedFlights[flightIndex]?.remarkCode?.key !== flightData?.remarkCode?.key) {
            // Trigger animation for flight remark change
            setAnimatedFlightIds((prev) => ({
              ...prev,
              [flightData._id]: true,
            }));
            // Remove animation after some time
            setTimeout(() => {
              setAnimatedFlightIds((prev) => ({
                ...prev,
                [flightData._id]: false,
              }));
            }, 1000); // Duration for animation
          }

          updatedFlights[flightIndex] = flightData;
        } else {
          updatedFlights = [flightData, ...prevFlights];
        }

        const currentTime = Date.now();
        const displayOffsetInMillis = displayOffset * 60 * 1000;

        updatedFlights = updatedFlights.filter(x => {
          if (!x.isDisplay) return false;
          if (x.remarkCode && x.remarkCode.key === RemarkCode.CANCELLED && currentTime > new Date(x.actualArrivalDepartureTime).getTime() + displayOffsetInMillis) return false;
          else if (x.lastBagReceivedAt && currentTime > new Date(x.lastBagReceivedAt).getTime() + displayOffsetInMillis) return false;
          else if (
            (x.remarkCode && x.remarkCode.key === RemarkCode.CLOSED_CCL) || (x.actualArrivalDepartureTime &&
              cargoCodes.includes(x.serviceTypeCode) &&
              currentTime > new Date(x.actualArrivalDepartureTime).getTime() + displayOffsetInMillis)
          ) {
            return false;
          }
          return true;
        });
        if (flightIndex === -1) {
          return updatedFlights.sort((a, b) => {
            return new Date(a.scheduledTravelTime).getTime() - new Date(b.scheduledTravelTime).getTime();
          });;
        }
        return updatedFlights;
      });

      let date = new Date();
      updateArrivals && updateArrivals(date);
    });

    return () => {
      socket.off('flight_update_arrival');
    };
  }, []);

  useEffect(() => {
    if (isDateChanges) fetchFlights();
  }, [isDateChanges]);

  useEffect(() => {
    socket.on("server_started", () => {
      console.log("Server started with socket ID:", socket.id);
      fetchFlights();
    });

    return () => {
      socket.off("server_started");
    };
  }, []);

  useEffect(() => {
    fetchFlights();
  }, []);

  useEffect(() => {
    const handleOnline = () => fetchFlights();
    window.addEventListener('online', handleOnline);
    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  const fetchFlights = async () => {
    try {
      const terminalId = (localStorage.getItem(STORAGE_TERMINAL_KEY));
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/flight-trips`, {
        params: {
          displayOffset,
          bagMovementMins,
          tripType: "Arrival",
          limit,
          isPassenger: lastPathSegment === "passenger",
          terminalId: terminalId !== "all" ? terminalId : null
        }
      });

      const { data } = response;
      setFlights(data);
      localStorage.setItem("fetchFlightsArrival", new Date().toString());

      const currentDate = new Date();
      updateArrivals(currentDate);

      const initialIndices: { [key: string]: number } = {};
      data.forEach((flight: FlightDetails) => {
        initialIndices[flight._id] = 0;
      });
      setCodeShareFlightIndices(initialIndices);
      setStationIndices(initialIndices);

    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCodeShareFlightIndices((prevIndices) => {
        const newIndices = { ...prevIndices };
        flights.forEach((flight) => {
          if (flight.codeShareFlight && flight.codeShareFlight.length > 0) {
            const currentIndex = prevIndices[flight._id] || 0;
            newIndices[flight._id] = (currentIndex + 1) % flight.codeShareFlight.length;
          }
        });
        return newIndices;
      });
    }, 3000); // 3-second interval

    return () => {
      clearInterval(intervalId);
    };
  }, [flights]);

  // Update stationIndices for flight stations
  useEffect(() => {
    const stationIntervalId = setInterval(() => {
      setStationIndices((prevIndices) => {
        const newIndices = { ...prevIndices };
        flights.forEach((flight) => {
          if (flight.stations && flight.stations.length > 1) {
            const currentIndex = prevIndices[flight._id] || 0;
            newIndices[flight._id] = (currentIndex + 1) % (flight.stations.length - 1); // Exclude the final destination
          }
        });
        return newIndices;
      });
    }, 3000);

    return () => {
      clearInterval(stationIntervalId);
    };
  }, [flights]);

  const checkLastStation = (flight: FlightDetails) => {
    if (
      (stationIndices[flight._id] % (flight.stations.length - 1))
      === 0) return true;
    return false;
  };


  // Keydown listener for CTRL+O
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'o') {
        event.preventDefault();
        setIsPopupVisible(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleConfirm = (terminalId: string) => {
    setSelectedTerminalId(terminalId);
    localStorage.setItem(STORAGE_TERMINAL_KEY, terminalId);
    socket.disconnect();
    fetchFlights();
    socket = createSocket(terminalId);
    socket.connect();
    
    console.log(`Selected Terminal: ${terminalId}`);
  };

  return (
    <div className="min-h-screen bg-gray-800 text-white">
      <FilterPopup
        selectedTerminalId={selectedTerminalId}
        show={isPopupVisible}
        onClose={() => setIsPopupVisible(false)}
        onConfirm={handleConfirm}
      />
      <table style={{ width: "100%" }} className={`w-full text-center ${isFading ? 'shimmer' : ''}`}>
        <thead className="bg-gray-700">
          <tr style={{ border: "1px solid" }}>
            {/* <th className="p-1 text-left flight">Terminal Id</th> */}
            <th className="p-1 text-white">STA</th>
            <th className="p-1 text-left flight">FLIGHT</th>
            <th className="p-1 text-left to">FROM</th>
            {lastPathSegment !== "passenger" && <th className="p-1">A/C Reg</th>}
            {lastPathSegment !== "passenger" && <th className="p-1 acf-type">A/C Type</th>}
            <th className="p-1">{lastPathSegment === "passenger" ? "Gate" : "BAY"}</th>
            <th className="p-1">REMARK</th>
            <th className="p-1">ETA</th>
          </tr>
        </thead>
        <tbody>
          {currentFlights.map((flight, index) => (
            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? "#0a8f6f" : "initial" }} className="odd:bg-gray-600 even:bg-gray-700">
              {/* <td className="p-1 eta">{flight.terminalId}</td> */}
              <td className="p-1">
                {moment(flight.scheduledTravelTime).utcOffset(timezone).format('HH:mm')}
                <br />
                <span className='text-arrival'>
                  {moment(flight.scheduledTravelTime).utcOffset(timezone).isSame(moment(), 'day')
                    ? '' // Don't show the date if it's today
                    : moment(flight.scheduledTravelTime).utcOffset(timezone).format('DD MMM')}
                </span>
              </td>
              <td className="p-1 text-left flight">
                <span>{flight.flightNumber}</span>
                {flight?.codeShareFlight?.length > 0 && <span> | </span>}
                <div className="code-share-animation" key={codeShareFlightIndices[flight._id]}>
                  <span></span> {flight.codeShareFlight[codeShareFlightIndices[flight._id] || 0]}
                </div>
              </td>
              <td className="p-1 text-left to">
                {flight.stations && flight.stations.length > 1 ? (
                  <>
                    <div
                      style={{ 'textDecoration': flight.stations.length > 2 && checkLastStation(flight) ? "underline" : "initial" }}
                      className="code-share-animation" key={"station" + stationIndices[flight._id]}>
                      <span>
                        {Cities[flight.stations[
                          (stationIndices[flight._id] % (flight.stations.length - 1))
                        ]?.airport] || (flight.stations[
                          (stationIndices[flight._id] % (flight.stations.length - 1))
                        ]?.airport || "")}
                      </span>
                    </div>
                  </>
                ) : (
                  <span>{flight.stations.length ? (Cities[flight.stations[0].airport] || flight.stations[0].airport) : ""}</span>
                )}
              </td>
              {lastPathSegment !== "passenger" && <td className="p-1">{flight.aircraftRegnNo}</td>}
              {lastPathSegment !== "passenger" && <td className="p-1">{flight.aircraftType}</td>}
              <td className="p-1">{lastPathSegment === "passenger" ? handleGateChange(flight) : flight.standCode}</td>
              <td className={"p-1 remark-col " + getDisplayColor(flight?.remarkCode?.key)}>
                <span className={`remark ${animatedFlightIds[flight._id] ? 'animate-swap' : ''}`}>
                  {flight?.remarkCode?.description}
                </span>
              </td>
              <td className="p-1 eta">
                {
                  <span>
                    {flight.ETA && flight?.remarkCode?.key != RemarkCode.CANCELLED &&
                      <span> {moment(flight.ETA).utcOffset(timezone).format('HH:mm')}
                        <br />
                        <small>
                          {flight.ETA && <span className='text-arrival'>
                            {moment(flight.ETA).utcOffset(timezone).isSame(moment(), 'day')
                              ? '' // Don't show the date if it's today
                              : moment(flight.ETA).utcOffset(timezone).format('DD MMM')}
                          </span>}</small>
                      </span>}
                  </span>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-center text-xl font-semibold my-2 fix-bottom">
        <button disabled={currentPage === 1} onClick={goToPreviousPage} className="btn btn-default btn-sm rounded text-white mr-1 arrival-bg">
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        Page {currentPage} of {totalPages}
        <button disabled={currentPage === totalPages} onClick={goToNextPage} className="btn btn-primary btn-sm rounded text-white ml-1 arrival-bg">
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    </div>
  );
};

export default ArrivalComponent;
