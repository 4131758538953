import React, { useEffect, useState } from 'react';
import { FlightDetails, FlightStatus, Rules } from '../interface/flight.details';
import moment from 'moment';
import axios from 'axios';
import { RemarkCode } from '../utils/remark.code';
import { Cities } from '../utils/cities';
// import socket from '../socket';
import { getDisplayColor, handleGateChange } from '../utils/common.utils';
import { useLocation } from 'react-router-dom';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterPopup from './FilterPopup';
import { io, Socket } from 'socket.io-client';
import { createSocket } from '../create.socket';

const url = process.env.REACT_APP_API_URL.split("://");
const socketURL = `ws${url[0] === "https" ? "s" : ""}://${url[1]}`;
const STORAGE_TERMINAL_KEY = "DepartureSelectedTerminalId";
let terminalId = localStorage.getItem(STORAGE_TERMINAL_KEY);
let socket = createSocket(terminalId || "all");
socket.connect();

interface DepartureComponentProps {
  updateArrivals: (data: Date) => void;
  rowsPerPage: number;
  isDateChanges?: boolean
}

const DepartureComponent: React.FC<DepartureComponentProps> = ({ updateArrivals, rowsPerPage, isDateChanges }) => {

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const limit = queryParams.get('limit');
  const path = location.pathname;
  const lastPathSegment = path.substring(path.lastIndexOf('/') + 1);

  const [flights, setFlights] = useState<FlightDetails[]>([]);
  const [rules, setRules] = useState<Rules[]>([]);
  const displayOffset = process.env.REACT_APP_DISPLAY_OFFSET_MINS || 20;
  const bagMovementMins = process.env.REACT_APP_BAG_MOVEMENT_MINS || 5;
  const [codeShareFlightIndices, setCodeShareFlightIndices] = useState<{ [key: string]: number }>({});
  const [stationIndices, setStationIndices] = useState<{ [key: string]: number }>({});
  const timezone = process.env.REACT_APP_TIMEZONE || 8;
  const [animatedFlightIds, setAnimatedFlightIds] = useState<{ [key: string]: boolean }>({}); // Track which flights are animating
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [isFading, setIsFading] = useState(false); // Track fading effect
  const [selectedTerminalId, setSelectedTerminalId] = useState(localStorage.getItem(STORAGE_TERMINAL_KEY) || "all");

  // Automatic page flipping

  const totalPages = Math.ceil(flights.length / rowsPerPage);
  const currentFlights = flights.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );


  useEffect(() => {
    // Apply fade-out and fade-in effect on page flip
    if (totalPages === 1) {
      setCurrentPage(1);
      return;
    };
    const interval = setInterval(() => {
      setIsFading(true); // Trigger fade-out
      setTimeout(() => {
        setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : 1));
        setIsFading(false); // Reset to fade-in
      }, 500); // Duration should match CSS transition
    }, currentPage === 1 ? 10 * 1000 : 5 * 1000);

    return () => clearInterval(interval);
  }, [currentPage, totalPages]);

  // Navigate between pages
  const goToPreviousPage = () => {
    setIsFading(true); // Trigger fade-out
    setTimeout(() => {
      setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : Math.ceil(flights.length / rowsPerPage)));
      setIsFading(false); // Reset to fade-in
    }, 500); // Duration should match CSS transition
  };

  const goToNextPage = () => {
    setIsFading(true); // Trigger fade-out
    setTimeout(() => {
      setCurrentPage((prevPage) => (prevPage < Math.ceil(flights.length / rowsPerPage) ? prevPage + 1 : 1));
      setIsFading(false); // Reset to fade-in
    }, 500); // Duration should match CSS transition
  };

  // Handle socket.io connection for real-time data

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected to server with socket ID:", socket.id);
    });

    return () => {
      socket.off("connect");
    };
  }, []);

  useEffect(() => {
    socket.on("disconnect", () => {
      console.log("Disconnected to server with socket ID:");
    });

    return () => {
      socket.off("disconnect");
    };
  }, []);

  useEffect(() => {
    socket.on('refresh_app', (message: any) => {
      console.log(message);
      // window.location.reload();
    });

    return () => {
      socket.off("refresh_app");
    };
  }, []);

  //rules 
  useEffect(() => {
    socket.on('rule_created', (message: any) => {
      console.log("rule_created", message);
      setRules([...rules, message]);
    });
    socket.on('rule_deleted', (message: any) => {
      console.log("rule_deleted", message);
      setRules(rules.filter(x => x._id !== message._id));
    });

    return () => {
      socket.off("rule_created");
      socket.off("rule_deleted");
    };
  }, []);

  useEffect(() => {
    // Listen to flight updates from the server
    socket.on('flight_update_departure', (flightData: FlightDetails) => {
      console.log("flight_update_departure", flightData);

      setFlights((prevFlights) => {
        const flightIndex = prevFlights.findIndex(flight => flight._id === flightData._id);
        // Update existing flight data or add new flight
        let updatedFlights;
        if (flightIndex !== -1) {
          updatedFlights = [...prevFlights];

          if (flightData?.remarkCode && updatedFlights[flightIndex]?.remarkCode?.key !== flightData?.remarkCode?.key) {
            // Trigger animation for flight remark change
            setAnimatedFlightIds((prev) => ({
              ...prev,
              [flightData._id]: true,
            }));
            // Remove animation after some time
            setTimeout(() => {
              setAnimatedFlightIds((prev) => ({
                ...prev,
                [flightData._id]: false,
              }));
            }, 1000); // Duration for animation
          }

          updatedFlights[flightIndex] = { ...flightData, standCodeChanged: flightData.standCode != updatedFlights[flightIndex].standCode };

        }
        else {
          updatedFlights = [flightData, ...prevFlights];
        }

        // Current time and other calculations
        const currentTime = Date.now(); // Get the current time in milliseconds
        const displayOffsetInMillis = displayOffset * 60 * 1000; // Offset minutes in milliseconds

        // Filter the flights based on isDisplay and ETA condition
        updatedFlights = updatedFlights
          .filter(item => {
            return item.isDisplay && (!item.actualArrivalDepartureTime || (item.actualArrivalDepartureTime && (new Date(item.actualArrivalDepartureTime).getTime() + displayOffsetInMillis > currentTime)));
          })
        if (flightIndex === -1) {
          return updatedFlights.sort((a, b) => {
            return new Date(a.scheduledTravelTime).getTime() - new Date(b.scheduledTravelTime).getTime();
          });
        }
        return updatedFlights;
      });

      updateArrivals && updateArrivals(new Date());
    });

    // Cleanup the socket connection when the component unmounts
    return () => {
      socket.off('flight_update_departure');
    };
  }, []);

  useEffect(() => {
    if (lastPathSegment === "passenger") {
      fetchRules();
    }
    if (isDateChanges) fetchFlights();
  }, [isDateChanges]);

  const fetchRules = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/rules`);
      setRules(response.data);
    } catch (err) {
      console.error("Failed to fetch rules:", err);
    }
  };

  const checkRule = (flight: FlightDetails) => {
    const now = moment();
    const flightETA = moment(new Date(flight?.ETA));
    if (
      flight?.remarkCode?.key === RemarkCode.GATE_OPEN
      || flight?.remarkCode?.key === RemarkCode.GATE_CLOSED
      || flight?.remarkCode?.key === RemarkCode.CANCELLED
      || flight?.remarkCode?.key === RemarkCode.FINAL_CALL
      || flight?.remarkCode?.key === RemarkCode.DEPARTED
      || now.isAfter(flightETA, 'minute')
    ) return null;
    return true;
  }

  const getRuleRemark = (flight: FlightDetails): FlightStatus | undefined | null => {
    const now = moment();
    const flightETA = moment(new Date(flight?.ETA));

    const gateShownRule = rules.find(rule => rule?.remarkCode?.key === RemarkCode.GATE_SHOWN); // Gate Shown
    const goToGateRule = rules.find(rule => rule?.remarkCode?.key === RemarkCode.GO_TO_GATE); // Go to Gate

    if (!gateShownRule || !goToGateRule) return null;

    const gateShownTime = flightETA.clone().subtract(gateShownRule.hours * 60, 'minutes');
    const goToGateTime = flightETA.clone().subtract(goToGateRule.hours * 60, 'minutes');

    if (now.isBetween(gateShownTime, goToGateTime)) {
      return { key: RemarkCode.GATE_SHOWN, description: `${gateShownRule?.remarkCode?.description} ${gateShownRule?.remarkCode?.key === RemarkCode.GATE_SHOWN ? " at " + gateShownTime.format('HH:mm') : ""}` };
    } else if (now.isSameOrAfter(goToGateTime)) {
      if (flight.standCodeChanged) return { key: RemarkCode.GATE_CHANGE, description: "Gate Change" };
      return { key: RemarkCode.GO_TO_GATE, description: `${goToGateRule?.remarkCode?.description} ${goToGateRule?.remarkCode?.key === RemarkCode.GATE_SHOWN ? " at " + goToGateTime.format('HH:mm') : ""}` };
    }

    return null;
  };


  useEffect(() => {
    socket.on("server_started", () => {
      console.log("Server started with socket ID:", socket.id);
      fetchFlights();
    });

    return () => {
      socket.off("server_started");
    };
  }, []);

  useEffect(() => {
    fetchFlights();
  }, []);

  useEffect(() => {
    const handleOnline = () => fetchFlights();
    window.addEventListener('online', handleOnline);
    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  const fetchFlights = async () => {
    try {
      const terminalId = (localStorage.getItem(STORAGE_TERMINAL_KEY));
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/flight-trips`, {
        params: {
          displayOffset, bagMovementMins,
          tripType: "Departure", limit,
          isPassenger: lastPathSegment === "passenger",
          terminalId: terminalId !== "all" ? terminalId : null
        }
      });

      const { data } = response;
      setFlights(data);
      localStorage.setItem("fetchFlightsDeparture", new Date().toString());

      const currentDate = new Date();
      updateArrivals(currentDate); // Update the last updated time


      const initialIndices: { [key: string]: number } = {};
      data.forEach((flight: FlightDetails) => {
        initialIndices[flight._id] = 0;
      });
      setCodeShareFlightIndices(initialIndices);
      setStationIndices(initialIndices);

    } catch (err) {
      console.error(err);
    } finally {
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCodeShareFlightIndices((prevIndices) => {
        const newIndices = { ...prevIndices };
        flights.forEach((flight) => {
          if (flight.codeShareFlight && flight.codeShareFlight.length > 0) {
            const currentIndex = prevIndices[flight._id] || 0;
            newIndices[flight._id] = (currentIndex + 1) % flight.codeShareFlight.length;
          }
        });
        return newIndices;
      });
    }, 3000); // 3-second interval

    return () => {
      clearInterval(intervalId);
    };
  }, [flights]);

  // Update stationIndices for flight stations
  useEffect(() => {
    const stationIntervalId = setInterval(() => {
      setStationIndices((prevIndices) => {
        const newIndices = { ...prevIndices };
        flights.forEach((flight) => {
          if (flight.stations && flight.stations.length > 1) {
            const currentIndex = prevIndices[flight._id] || 0;
            newIndices[flight._id] = (currentIndex + 1) % (flight.stations.length - 1); // Exclude the final destination
          }
        });
        return newIndices;
      });
    }, 3000);

    return () => {
      clearInterval(stationIntervalId);
    };
  }, [flights]);


  const checkLastStation = (flight: FlightDetails) => {
    if (flight.stations.length - 1 ===
      (stationIndices[flight._id] % (flight.stations.length - 1)) + 1
    ) return true;
    return false;
  };

  // Keydown listener for CTRL+O
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'o') {
        event.preventDefault();
        setIsPopupVisible(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleConfirm = (terminalId: string) => {
    setSelectedTerminalId(terminalId);
    localStorage.setItem(STORAGE_TERMINAL_KEY, terminalId);
    socket.disconnect();
    fetchFlights();
    socket = createSocket(terminalId);
    socket.connect();
    console.log(`Selected Terminal: ${terminalId}`);
  };

  return (
    <div className="min-h-screen bg-gray-800 text-white">
      <FilterPopup
        selectedTerminalId={selectedTerminalId}
        show={isPopupVisible}
        onClose={() => setIsPopupVisible(false)}
        onConfirm={handleConfirm}
      />
      <table style={{ width: "100%" }}
        className={`w-full text-center ${isFading ? 'shimmer' : ''}`}
      >
        <thead className="bg-gray-700">
          <tr style={{ border: "1px solid" }}>
            <th className="p-1 text-white eta">STD</th>
            <th className="p-1 text-left flight">FLIGHT</th>
            <th className="p-1 text-left to">TO</th>
            {lastPathSegment !== "passenger" && <th className="p-1">A/C Reg</th>}
            {lastPathSegment !== "passenger" && <th className="p-1 acf-type">A/C Type</th>}
            <th className="p-1">{lastPathSegment === "passenger" ? 'Gate' : 'BAY'}</th>
            <th className="p-1 remark-col">REMARK</th>
            <th className="p-1 eta">ETD</th>
          </tr>
        </thead>
        <tbody>
          {currentFlights.map((flight, index) => (
            <tr style={{ backgroundColor: index % 2 == 0 ? "#044be3" : "initial" }} key={index} className="odd:bg-gray-600 even:bg-gray-700">
              <td className="p-1 eta">
                {moment(flight.scheduledTravelTime).utcOffset(timezone).format('HH:mm')}

                <span>
                  {
                    moment(flight.scheduledTravelTime).utcOffset(timezone).isSame(moment(), 'day')
                      ? '' // Don't show the date if it's today
                      : (
                        <span>
                          <span>, </span>
                          <small className='text-arrival'>{moment(flight.scheduledTravelTime).utcOffset(timezone).format('DD MMM')}</small>
                        </span>
                      )}
                </span>
              </td>
              <td className="p-1 text-left flight">
                <span>{flight.flightNumber}</span>
                {flight?.codeShareFlight?.length > 0 && <span> | </span>}
                <div className="code-share-animation" key={codeShareFlightIndices[flight._id]}>
                  <span></span> {flight.codeShareFlight[codeShareFlightIndices[flight._id] || 0]}
                </div>
              </td>
              <td className="p-1 text-left to">
                {flight.stations && flight.stations.length > 1 ? (
                  <>
                    <div
                      style={{ 'textDecoration': flight.stations.length > 2 && checkLastStation(flight) ? "underline" : "initial" }}
                      className="code-share-animation" key={"station" + stationIndices[flight._id]}>
                      <span>
                        {Cities[flight.stations[
                          (stationIndices[flight._id] % (flight.stations.length - 1)) + 1
                        ]?.airport] || (flight.stations[
                          (stationIndices[flight._id] % (flight.stations.length - 1)) + 1
                        ]?.airport || "")}
                      </span>
                    </div>
                  </>
                ) : (
                  <span>{flight.stations.length ? (Cities[flight.stations[0].airport] || flight.stations[0].airport) : ""}</span>
                )}
              </td>
              {lastPathSegment !== "passenger" && <td className="p-1">{flight.aircraftRegnNo}</td>}
              {lastPathSegment !== "passenger" && <td className="p-1 acf-type">{flight.aircraftType}</td>}
              <td className="p-1"> {lastPathSegment === "passenger" ? (getRuleRemark(flight)?.key === RemarkCode.GATE_SHOWN ? "" : handleGateChange(flight)) : flight.standCode}</td>
              <td
                className={"p-1 remark-col " + getDisplayColor(flight?.remarkCode?.key)}>
                <span className={`remark ${animatedFlightIds[flight._id] ? 'animate-swap' : ''}`}>
                  {lastPathSegment === "passenger" && flight.ETA && checkRule(flight) && flight?.remarkCode?.key !== RemarkCode.DELAYED ? "" : flight?.remarkCode?.description}
                  {lastPathSegment === "passenger" && flight.ETA && checkRule(flight) && (
                    <span style={{ fontSize: !checkRule(flight) ? "small" : "23px" }} className="text-arrival"> {getRuleRemark(flight)?.description}</span>
                  )}
                </span>
              </td>
              <td className="p-1 eta">
                {
                  <span>
                    {flight.ETA && flight?.remarkCode?.key != RemarkCode.CANCELLED &&
                      <span> {moment(flight.ETA).utcOffset(timezone).format('HH:mm')}
                        {flight.ETA && <span >
                          <small>
                            {moment(flight.ETA).utcOffset(timezone).isSame(moment(), 'day')
                              ? '' // Don't show the date if it's today
                              : (
                                <span>
                                  <span>, </span>
                                  <small className='text-arrival'>{moment(flight.ETA).utcOffset(timezone).format('DD MMM')}</small>
                                </span>
                              )
                            }
                          </small></span>}
                      </span>}
                  </span>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="text-center text-xl font-semibold my-2 fix-bottom">
        <button disabled={currentPage === 1} onClick={goToPreviousPage} className="btn btn-primary btn-sm rounded text-white mr-1">
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>

        Page {currentPage} of {totalPages}
        <button disabled={currentPage === totalPages} onClick={goToNextPage} className="btn btn-primary btn-sm rounded text-white ml-1">
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>

    </div>
  );
};

export default DepartureComponent;
