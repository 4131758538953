// src/socket.ts
import { io, Socket } from 'socket.io-client';

const url = process.env.REACT_APP_API_URL.split("://");
const socketURL = `ws${url[0] === "https" ? "s" : ""}://${url[1]}`;

export const createSocket = (terminalId: string): Socket => {
  return io(socketURL, {
    transports: ['websocket'],
    autoConnect: false,
    query: { terminalId },
  });
};
